import React from 'react'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import styled from 'styled-components'
import ImageBase from 'gatsby-image'

const Image = (props) => {
  const parentHeight = typeof window !== 'undefined' && window.innerHeight - 130
  const getImgWidth = (aspectRatio, parentWidth) => {
    if(aspectRatio * parentHeight < parentWidth){
      return `${aspectRatio*parentHeight}px`
    }
    return 'auto'
  }
  
  const getImgHeight = (aspectRatio, parentWidth) => {
    if(aspectRatio * parentHeight > parentWidth){
      return `${parentWidth/2}px`
    }
    return 'auto'
  }
  const Image = styled(ImageBase)`
    width: ${props => getImgWidth(props.aspectRatio, props.parentWidth)};
    height: ${props => getImgHeight(props.aspectRatio, props.parentWidth)};
    margin: auto;
    position: relative;
  `
  return <Image {...props} />
}

export default Image