import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../Layout'
import HomePage from '../HomePage'
import SEO from '../SEO/SEO'

export const query = graphql`
query($pageName: String!) {
  page: contentfulPageWithSlider(pageName: { eq: $pageName }) {
    pageName
    sliderImages {
      description
      title
      fluid(quality:100, maxWidth: 2400) {
        ...GatsbyContentfulFluid_withWebp
      }
    }
  }
}
`

const PageWithSlider = ({ data }) => {
  const { page } = data
  return (
    <div width='100%'>
      <SEO lang={'en'} title={page.pageName} />
      <Layout>
        {(({onSlideChange}) =>{
          return <HomePage data={page} onSlideChange={onSlideChange} lang={'en'} />
        })}
      </Layout>
    </div>
  )
}

export default PageWithSlider
