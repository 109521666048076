import React from 'react'
import styled from 'styled-components/macro'

const ImageContainer = ({children}) => {
  const parentHeight = typeof window !== 'undefined' && window.innerHeight - 130
  const ImageContainerBase = styled.div`
    height: ${parentHeight}px;
    position: relative;
    :focus {
      outline: none!important;
    }
  `
  return <ImageContainerBase>{children}</ImageContainerBase>
}

export default ImageContainer