import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import React, { useEffect ,useRef } from 'react'
import SliderBase from 'react-slick'
import styled from 'styled-components/macro'
import Box from '../../components/Box'
import ImageContainer from './ImageContainer'
import Image from './SliderImage'

const Slider = styled(SliderBase)`
`
const Controller = styled(Box)`
  position: absolute;
  height: 100%;
  width: 50%;
  z-index: 20;
  right: ${props=> props.right && 0};
  cursor: url("/arrows.png"), auto!important;
`

const SliderWrapper = (props) => {
  const slider = useRef()

  useEffect(()=>{
    props.onSlideChange(props.sliderItems[0])
  }, [])

  const handleNextClick = ()=>{
    slider.current.slickNext()
  }

  const handlePrevClick = ()=>{
    slider.current.slickPrev()
  }
  
  const settings = {
    dots: false,
    accessibility: false,
    fade: true,
    speed: 500,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: false,
    beforeChange: (current, next) => props.onSlideChange(props.sliderItems[next])
  }
  return (
    <Box>
      <Slider ref={slider} className="slider" {...settings}>
        {props.sliderItems.map((img, i) => {
          return (
            <ImageContainer key={`slider-${i}`}>
              <Controller onClick={handlePrevClick} />
              <Controller  onClick={handleNextClick} right />
              <Image 
                parentWidth={props.parentWidth}
                aspectRatio={img.fluid.aspectRatio}
                fluid={img.fluid}
                alt={img.title} />
            </ImageContainer>
          )
        })}
      </Slider>
    </Box>
  )
}
export default SliderWrapper
