import React, { useRef } from 'react'
import Slider from './Slider'
import Gallery from './Gallery'
import Box from '../../components/Box'
import Fade from '../../components/Fade'

const HomePage = ({data, onSlideChange}) => {
  const homeRef = useRef()
  const parentWidth = homeRef.current && homeRef.current.getBoundingClientRect().width || 700

  return (
    <Box width={parentWidth > 960 ? 'calc(100% - 320px)' : '100%'} position='relative' ref={homeRef} pt={[0,'130px']} >
      <Fade>
        <Box display={['none', 'none' ,'block']} margin='auto' width='100%'>
          <Slider parentWidth={parentWidth} onSlideChange={onSlideChange}
            sliderItems={data.sliderImages}
          />
        </Box>
        <Box display={['block', 'block' ,'none']} width='100%'>
          <Gallery images={data.sliderImages} />
        </Box>
      </Fade>
    </Box>)
}

export default HomePage
