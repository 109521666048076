import React from 'react'
import ImageBase from 'gatsby-image'
import styled from 'styled-components/macro'
import Box from '../../components/Box'
import Caption from '../Caption'
import Flex from '../../components/Flex'

const Image = styled(ImageBase)`
  width:'100%';
`
const SliderWrapper = ({images}) => {
  return (
    <Box px='16px'>
      {images.map(img=>{
        return <Flex key={img.title} flexDirection='column'>
          <Image fluid={img.fluid} alt={img.title} />
          <Caption image={img} />
        </Flex>
      })}
    </Box>
  )
}
export default SliderWrapper
